import { createContext } from "react";

export const english_text = {

    home: {
        dashboard: "Dashboard",
        add_service: "Add Service",
        services: "Services",
        profile: "Profile ",
        logout: "Logout",
        visitSite: "Visit Site"
    },
    dashboard: {
        totalServices: "Total Services",
        totalCalls: "Total Calls",
        averageRating: "Average Rating",
        views: "Views",
        calls: "Calls",
        thisWeekSummary: "This Week Summary",
        thisMonthSummary: "This Month Summary",
    },
    add_service: {
        createService: "Create Service",
        basic: {
            text0: 'Birr',
            text1: '39/Year',
            text2: 'Basic Service',
            text3: 'For services that take minimal space when displayed to clients',
            text4: 'Title',
            text5: 'Full Name',
            text6: 'rating',
            text7: 'Shown in List Format',
        },
        standard: {
            text0: 'Birr',
            text1: '59/Year',
            text2: 'Standard Service',
            text3: 'For services that will be displayed in a card format to clients',
            text4: 'Title',
            text5: 'Full Name',
            text6: 'Header Image',
            text7: 'Overview',
            text8: 'Shown in a card format',
            text9: 'Shown at top',
        },
        premium: {
            text0: 'Birr',
            text1: '79/Year',
            text2: 'Premium Service',
            text3: 'For services that will be displayed in a card form and have detail pages',
            text4: 'Title',
            text5: 'Full Name',
            text6: 'Header Image',
            text7: 'Overview',
            text8: 'Shown in a card format',
            text9: 'Shown at top',
            text10: 'Contains a detail page',
        }
    },
    profile: {
        profilePicture: "Profile Picture",
        profilePictureDescription: "JPG or PNG no larger than 5 MB",
        availability: "Availability",
        yourWorkingLocation: "Your Working Location",
        areasCovered: "Areas Covered",
        yourWorkingTrade: "Your Working Trade",
        yourDetails: "Your Details",
        firstName: "First Name",
        lastName: "Last Name",
        companyName: "Company Name",
        yourContactDetails: "Your contact Details",
        address1: "Address 1",
        address2: "Address 2",
        city: "City",
        country: "Country",
        telephoneLandline: "Telephone(Landline)",
        mobile: "Mobile",
        email: "Your",
        howDidYouHearAboutUs: "How did you hear about us",
        whereDidYouHearAboutUs: "Where did you hear about us",
        saveChanges: "Save Changes",
    },
    create_service: {
        basicInfo: "Basic Info",
        serviceTitle: "Service Title",
        category: "Category",
        subCategory: "Sub Category",
        price: "Price",
        overview: "Overview",
        selectImage: "Select Image",
        imageInfo: "Service Image(should be less than 5MB)",
        aboutYou: "About You",
        city: "Sub",
        specificLocation: "Specific Location",
        location: "Location",
        bio: "Bio",
        serviceDetail: "Service Detail",
        notes: "notes",
        note1Title: "Add content:",
        note1Text: "To add content, simply click inside the editor and start typing. You can also paste text from another source, like a Word document or email.",
        note2Title: "Formatting:",
        note2Text: "To format your text, use the toolbar at the top of the editor. You can change the font, size, and color of your text, as well as add bold, italic, and underline formatting. You can also create bulleted or numbered lists and align your text to the left, center, or right.",
        note3Title: "Add links:",
        note3Text: "To add links to your content, select the text you want to link and click on the 'Insert Link' button in the toolbar. You can link to another page on our website or to an external website.",
        previewService: "Preview Service",
        previewDetailPage: "Preview Detail Page",
        save: "Save"
    },
}

export const amhairc_text = {
    home: {
        dashboard: "ዳሽቦርድ",
        add_service: "አገልግሎት ጨምር",
        services: "አገልግሎቶች",
        profile: "መገለጫ",
        logout: "ውጣ ",
        visitSite: "ጣቢያን ይጎብኙ"
    },
    dashboard: {
        totalServices: "ጠቅላላ አገልግሎቶች",
        totalCalls: "ጠቅላላ ጥሪዎች",
        averageRating: "አማካይ ደረጃ አሰጣጥ",
        views: "እይታዎች",
        calls: "ጥሪዎች",
        thisWeekSummary: "የዚህ ሳምንት ማጠቃለያ",
        thisMonthSummary: "የዚህ ወር ማጠቃለያ",
    },
    add_service: {
        createService: "አገልግሎት ፍጠር",
        basic: {
            text0: '',
            text1: 'በዓመት 39 ብር',
            text2: 'መሰረታዊ አገልግሎት',
            text3: 'ለደንበኞች ሲታዩ አነስተኛ ቦታ ለሚወስዱ አገልግሎቶች',
            text4: 'ርዕስ',
            text5: 'ሙሉ ስም',
            text6: 'ደረጃ መስጠት',
            text7: 'በዝርዝር  ይታያል',
        },
        standard: {
            text0: '',
            text1: 'በዓመት 59 ብር',
            text2: 'መደበኛ አገልግሎት',
            text3: 'በካርድ ቅርጸት ለደንበኞች ለሚታዩ አገልግሎቶች',
            text4: 'ርዕስ',
            text5: 'ሙሉ ስም',
            text6: 'የራስጌ ምስል',
            text7: 'አጠቃላይ እይታ',
            text8: 'በካርድ ቅርጸት ይታያል',
            text9: 'ከላይ ይታያል',
        },
        premium: {
            text0: '',
            text1: 'በዓመት 79 ብር',
            text2: 'የፕሪሚየም አገልግሎት',
            text3: 'በካርድ ቅጽ ላይ ለሚታዩ እና ዝርዝር ገፆች ላሏቸው አገልግሎቶች',
            text4: 'ርዕስ',
            text5: 'ሙሉ ስም',
            text6: 'የራስጌ ምስል',
            text7: 'አጠቃላይ እይታ',
            text8: 'በካርድ ቅርጸት ይታያል',
            text9: 'ከላይ ይታያል',
            text10: 'ዝርዝር ገጽ ይዟል',
        }
    },
    profile: {
        profilePicture: "የመገለጫ ሥዕል",
        profilePictureDescription: "JPG ወይም PNG ከ 5 ሜባ አይበልጥም",
        availability: "ተገኝነት",
        yourWorkingLocation: "የስራ ቦታዎ",
        areasCovered: "የተሸፈኑ ቦታዎች",
        yourWorkingTrade: "የእርስዎ የስራ ንግድ",
        yourDetails: "ዝርዝሮችህ",
        firstName: "የመጀመሪያ ስም",
        lastName: "የአያት ስም",
        companyName: "የድርጅት ስም",
        yourContactDetails: "የእውቂያ ዝርዝሮችዎ",
        address1: "አድራሻ 1",
        address2: "አድራሻ 2",
        city: "ከተማ",
        country: "ሀገር",
        telephoneLandline: "ስልክ(የመስመር ስልክ)",
        mobile: "ሞባይል",
        email: "ኢሜይል",
        howDidYouHearAboutUs: "እንዴት ስለእኛ ሰማህ",
        whereDidYouHearAboutUs: "ስለ እኛ የት ሰማህ",
        saveChanges: "ለውጦችን አስቀምጥ",
    },
    create_service: {
        basicInfo: "መሰረታዊ መረጃ",
        serviceTitle: "የአገልግሎት ርዕስ",
        category: "ምድብ",
        subCategory: "ንዑስ ምድብ",
        price: "ዋጋ",
        selectImage: "ምስል ይምረጡ",
        imageInfo: "የአገልግሎት ምስል(ከ5ሜባ ያነሰ መሆን አለበት)",
        overview: "አጠቃላይ እይታ",
        aboutYou: "ስለ አንተ",
        city: "ከተማ",
        specificLocation: "የተወሰነ ቦታ",
        location: "አካባቢ",
        bio: "ባዮ",
        serviceDetail: "የአገልግሎት ዝርዝር",
        notes: "ማስታወሻዎች",
        note1Title: "ይዘት አክል፡",
        note1Text: "ይዘትን ለመጨመር በቀላሉ በአርታዒው ውስጥ ጠቅ ያድርጉ እና መተየብ ይጀምሩ። እንደ Word ሰነድ ወይም ኢሜል ካሉ ከሌላ ምንጭ ጽሑፍ መለጠፍ ይችላሉ።",
        note2Title: "በመቅረጽ ላይ፡",
        note2Text: "ጽሑፍህን ለመቅረጽ በአርታዒው አናት ላይ ያለውን የመሳሪያ አሞሌ ተጠቀም። የጽሑፍህን ቅርጸ ቁምፊ፣ መጠን እና ቀለም መቀየር ትችላለህ፣ እንዲሁም ደፋር፣ ሰያፍ እና ከስር ስር ቅርጸት ማከል ትችላለህ። እንዲሁም ነጥበ ምልክት የተደረገባቸው ወይም የተቆጠሩ ዝርዝሮችን መፍጠር እና ጽሁፍዎን ወደ ግራ፣ መሃል ወይም ቀኝ ማስተካከል ይችላሉ።",
        note3Title: "አገናኞችን አክል፡",
        note3Text: "ወደ ይዘትህ አገናኞችን ለመጨመር ማገናኘት የምትፈልገውን ጽሑፍ ምረጥ እና በመሳሪያ አሞሌው ላይ ያለውን 'አገናኝ አስገባ' የሚለውን ቁልፍ ተጫን። በድረ-ገጻችን ላይ ወደ ሌላ ገጽ ወይም ወደ ውጫዊ ድረ-ገጽ ማገናኘት ይችላሉ",
        previewService: "Preview Service",
        previewDetailPage: "Preview Detail Page",
        save: "Save"
    },
}


export const _seller = {
    _id: "",
    username: "",
    firstName: "",
    lastName: "",
    img: "",
    email: "",
    services: [],
    phoneNumber: "",
    companyName: "",
    address1: "", 
    address2: "",
    city: "", 
    country: "",
    telephone: "", 
    howDidYouHear: "",
    whereDidYouHear: "",
    workingLocation: "", 
    areasCovered: "",
    language: "english",
    text: english_text,
}

export const SellerContext = createContext(
    _seller
)