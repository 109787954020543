

// const BACKEND_HOST="https://seri-ena-aseri.onrender.com"
// const BACKEND_HOST="https://seri-ena-aseri.vercel.app"
// const BACKEND_HOST="http://localhost:5000"
//admin password=y#hs^df34jdj0
//admin email=serrale@gmail.com
const ADMIN_HIDDEN_URL="aldf45233kjdfj0-3482034234-hidden-link-sdkfj-dfd"
const SUB_ADMIN_HIDDEN_URL="aldf45233kjdfj0-3482034234-hidden-link-sdkfj-dfd-sub-admin"
const BACKEND_HOST="https://www.serrale.com"

export  {
    BACKEND_HOST,
    ADMIN_HIDDEN_URL,
    SUB_ADMIN_HIDDEN_URL
}