import { createContext } from "react";

export const english_text = {
    navbar: {
        howTo: "How To",
        leaveAReview: "Leave A Review",
        signIn: "Sign In",
        joinUs: "Join Us",
    },
    home: {
        // text1: `Your go-to destination for top-tier Ethiopian Talent`,
        text1: `Find the right professional today`,
        text2: `Order any service, anytime from anywhere`,
        text3: `what are you looking for?`,
        text4: `FEATURED SERVICES`,
        text5: `Service Categories`,
        text6: `Join Us and Start Selling Your Services On Our Website`,
        text7: `Its free to join.`,
        text8: `Sell Your Services`,
        text9: "Book keeping",
        text10: "Auditing",
        text11: "Tax consultancy",
        text12: "Administration",
        text13: "Human resource",
        text14: "Clark",
        text15: "Aerial and satellite installers",
        text16: "Bathroom fitters and designers",
        text17: "Boiler installation",
        text18: "Builders",
        text19: "Burglar alarms",
        text20: "Carpenters",
        text21: "Explore All",
        text22: "Serrale",
        text23: "Become A Member",
        text24: "Apply Online",
        text25: "View all categories",
        text26: "Join Us and Start Selling Your",
        text27: "Services On Our Website",
        text28: "it is free to join",
        text29: "Know How To Sell!",
    },
    about: {
        text1: 'Know About Us',
        text2: 'Welcome to SERRALE, a platform connecting Service providers and qualified individuals with local and international users. Our team of experts work tirelessly to ensure that our platform is always up to date and running smoothly.',
        text3: 'We carefully vet all of our Service providers to ensure that they have the qualifications and experience needed to provide top-quality service.',
        text4: 'Our goal is to make it easy for customers find the professional they need, and for professionals to find the customers and projects they want.',
        text5: 'Our platform is accessible from anywhere, and it is mobile-friendly, making it easy for users to access it from any device',
        text6: 'Why our Market place',
        text7: "Our goal is to make it easier for our customers to find the right professionals for their needs and to allow them to advertise their skills and services. Finding the right expert can be difficult, so it's our mission to make the process as simple and clear as possible so you can harness the power of finding the right expert for your project.",
        text8: 'Service Commitment',
        text9: 'Our dedicated customer service team is always available to assist you in finding the right professional for the service you require and ensuring a smooth.',
        text10: 'User Data Secure',
        text11: 'We comply with all applicable laws and regulations regarding the collection, use, and storage of personal data',
        text12: 'Fast service',
        text13: 'Our customer service team is dedicated to always ensure that all your enquiries are answered whether you are a professional or looking for a professional with fast responses ensuring a smooth experience for all users on the platform.',
        text14: 'Secure payment',
        text15: 'We strive to instil confidence in users on the platform with our secure and reliable payment methods, at SERRALEyour privacy is in safe hands.',
        text16: 'Dedicated Support',
        text17: 'Our customer service team is available to assist with any questions or concerns. We value your feedback and are constantly working to improve our platform.',
    },
    how_to: {
        text1: 'Welcome to the SERRALE Service provides registration guide. We are thrilled to have you on board. This guide will walk you through the process of registering you on this platform',
        text2: 'Register',
        text3: 'To register, you will need to create an account on our platform',
        text4: 'Dashboard Setup',
        text5: 'You will be directed to your own account dashboard. Here you are required to add your personal information, including your qualifications, work experience, and skills.',
        text6: 'Choose Membership',
        text7: 'Choose your membership type on your account dashboard.',
        text8: 'Premium',
        text9: 'Standard',
        text10: 'Basic',
        text11: 'Creating Service',
        text12: 'add your requirements. This includes your hourly rate, availability, and the types of projects you are willing to work on.',
        text13: 'Publish Your Service!',
        text14: 'After you have completed all the above steps, you have to make payment to publish your profile. Once you make the payment, the button that will allow you to publish your profile will be visible. Please note: until you make the payment, the publish button won’t display and your profile won’t be visible to potential clients and allow them to contact you for your services',
        text15: 'Join Us and Start Selling Your Services On Our Website',
        text16: 'We hope this guide has been helpful in getting you started on the SIRA ALLE platform. If you have any further questions or need assistance, please do not hesitate to contact our customer service team. We wish you the best of luck in finding your next project and connecting with new clients on the SIRA ALLE platform.',
        text17: 'Sell Your Services',


    }
}

export const amhairc_text = {
    navbar: {
        howTo: "እንዴት ነው",
        leaveAReview: "ግምገማ ይተው",
        signIn: "ስግን እን",
        joinUs: "ተቀላቀለን",
    },
    home: {
        text1: `ለአገልግሎቶችዎ ሁነኛ  መፍትሄ`,
        text2: `ማንኛውንም አገልግሎት በማንኛውም ጊዜ ከየትኛውም ቦታ ይዘዙ`,
        text3: `የሚፈልጉት አገልግሎት  ምንድን ነው?`,
        text4: `ለየት ያሉ  አገልግሎቶች`,
        text5: `የአገልግሎት ምድቦች`,
        text6: `ይቀላቀሉን እና አገልግሎቶችዎን በድረ-ገፃችን ላይ መሸጥ ይጀምሩ`,
        text7: `ለመቀላቀል ነፃ ነው።`,
        text8: `አገልግሎቶን ይሽጡ`,
        text9: "መጽሐፍ መያዝ",
        text10: "ኦዲት ማድረግ ",
        text11: "የግብር አማካሪ",
        text12: "አስተዳደር",
        text13: "የሰው ኃይል",
        text14: "ክላርክ",
        text15: "የአየር እና የሳተላይት መጫኛዎች",
        text16: "የመታጠቢያ ቤት እቃዎች እና ዲዛይነሮች",
        text17: "የቦይለር መጫኛግንበኞች ",
        text18: "ግንበኞች",
        text19: "የዝርፊያ ማንቂያዎች",
        text20: "አናጺዎች",
        text21: "ሁሉንም ያስሱ",
        text22: "ሥራለ",
        text23: "አባል መሆን",
        text24: "በመስመር ላይ ያመልክቱ",
        text25: "ሁሉንም ምድቦች ይመልከቱ",        
        text26: "ይቀላቀሉን እና የእርስዎን መሸጥ ይጀምሩ",
        text27: "በድረ-ገፃችን ላይ ያሉ አገልግሎቶች",
        text28: "መቀላቀል ነፃ ነው።",
        text29: "እንዴት እንደሚሸጡ ይወቁ!",
    },
    about: {
        text1: 'ስለ እኛ ገጽ',
        text2: 'እንኳን ወደ SERA, ባለሙያዎችን እና ብቁ ግለሰቦችን ከሀገር ውስጥ እና አለምአቀፍ ተጠቃሚዎች ጋር የሚያገናኝ መድረክ እንኳን ደህና መጡ። የእኛ የባለሙያዎች ቡድን የእኛ መድረክ ሁል ጊዜ የተዘመነ እና ያለችግር እንዲሰራ ለማድረግ ያለመታከት ይሰራሉ።',
        text3: 'ሁሉንም ባለሙያዎቻችን ከፍተኛ ጥራት ያለው አገልግሎት ለመስጠት የሚያስፈልጉት ብቃቶች እና ልምድ እንዳላቸው ለማረጋገጥ በጥንቃቄ እንመረምራለን።',
        text4: 'ግባችን ደንበኞች የሚፈልጉትን ባለሙያ እንዲያገኙ እና ባለሙያዎች የሚፈልጉትን ደንበኞች እና ፕሮጀክቶች እንዲያገኙ ማድረግ ነው።',
        text5: 'የእኛ መድረክ ከየትኛውም ቦታ ተደራሽ ነው፣ እና ለሞባይል ምቹ ነው፣ ይህም ተጠቃሚዎች ከማንኛውም መሳሪያ በቀላሉ ማግኘት ይችላሉ።',
        text6: 'ለምን የእኛ የገበያ ቦታ',
        text7: 'ተጠቃሚዎቻችን ለፍላጎታቸው ትክክለኛውን አገልግሎት አቅራቢ እንዲያገኙ እና ባለሙያዎች ክህሎቶቻቸውን እና አገልግሎቶቻቸውን እንዲያሳዩ እንከን የለሽ ተሞክሮ ለማቅረብ ቆርጠን ተነስተናል።',
        text8: 'የአገልግሎት ቁርጠኝነት',
        text9: 'ለሚፈልጉት አገልግሎት ትክክለኛውን አገልግሎት አቅራቢ ለማግኘት እና ለስላሳ ልምድን ለማረጋገጥ የእኛ ቁርጠኛ የደንበኞች አገልግሎት ቡድን ሁል ጊዜ ይገኛል።',
        text10: 'የተጠቃሚ መረጃ ደህንነቱ የተጠበቀ',
        text11: 'የተጠቃሚዎቻችንን ውሂብ የመጠበቅን አስፈላጊነት እንረዳለን። ለዛም ነው በመድረክ ላይ የሚጋሩት ሁሉም መረጃዎች ደህንነታቸው የተጠበቀ እና ደህንነቱ የተጠበቀ መሆኑን ለማረጋገጥ ጥብቅ የደህንነት እርምጃዎችን ተግባራዊ ያደረግነው። የእኛ መድረክ የተገነባው ደህንነቱ በተጠበቀ ሁኔታ ላይ ነው።',
        text12: 'ፈጣን አገልግሎት',
        text13: 'በተቻለ መጠን ፈጣን እና ቀልጣፋ አገልግሎት ለተጠቃሚዎቻችን ለማቅረብ እንተጋለን ። የእኛ የደንበኞች አገልግሎት ቡድን እርስዎ ባለሙያ ከሆኑ ወይም በመድረኩ ላይ ላሉ ሁሉም ተጠቃሚዎች ለስላሳ ተሞክሮ የሚያረጋግጡ ፈጣን ምላሾች ያለው ባለሙያ ለጥያቄዎችዎ ምላሽ እንዲያገኙ ሁል ጊዜ ቁርጠኛ ነው።',
        text14: 'ደህንነቱ የተጠበቀ ክፍያ',
        text15: 'በአስተማማኝ እና አስተማማኝ የመክፈያ ዘዴዎቻችን በመድረክ ላይ በተጠቃሚዎች ላይ እምነት ለመፍጠር እንጥራለን፣ በ ሥራለ ግላዊነትዎ ደህንነቱ በተጠበቀ እጅ ነው።',
        text16: 'የተሰጠ ድጋፍ',
        text17: 'የኛ የደንበኞች አገልግሎት ቡድን ለማንኛውም ጥያቄዎች ወይም ስጋቶች ለመርዳት ዝግጁ ነው። የእርስዎን አስተያየት ዋጋ እንሰጣለን እና የእኛን መድረክ ለማሻሻል በቋሚነት እየሰራን ነው።',
    },
    how_to: {
        text1: 'ወደ  ሥራለ  አገልግሎት አቅራቢ ምዝገባ መመሪያ እንኳን በደህና መጡ። ይህ መመሪያ አንደባለሙያ እንዴት እንደሚመዘገቡ ይመራዎታል።',
        text2: '',
        text3: 'ደንበኛ ለመሆን ፣ በዘህ መድረክ ላይ መመዝገብ ያስፈልግዎታል። ለመመዝገብ በመነሻ/በመጀመሪያ ገፅ  ከላይ በቀኝ በኩል የሚገኘውን  “ይመዝገቡ” ቁልፍ ይክፈቱት',
        text4: '',
        text5: 'ይህ ወደ እርስዎ መለያ ገፅ ያመራዎታል። በዚህ ገፅ የእርስዎን የግል መረጃ ፣መመዘኛዎች፣ የትምህርት መረጃዎች ፣ የስራ ልምድ እና ችሎታዎች ጨምሮ የተለያዩ  ተዛማጅ መረጃዎች ማስገባት ይኖርቦታል። ደንበኞች በቀላሉ እንዲያገኙዎት እና በሚመለከተው መስክ ላይ ያለዎትን እውቀት እና ልምድ እንዲረዱ ሁሉንም ተዛማጅ መረጃዎችን ማካተትዎን ያረጋግጡ።',
        text6: '',
        text7: 'በደንበኛ ልዩ ገፅ ላይ የአባልነት አይነትዎን ይምረጡ',
        text8: 'ፕሪሚየም',
        text9: 'መደበኛ',
        text10: 'መሰረታዊ',
        text11: '',
        text12: 'የግል መረጃዎን እና መመዘኛዎችዎን ከመዘገቡ በኋላ የእርስዎን ዋና ዋና መስፈርቶች ይመዝግቡ። ይህም በሰኣት አንዲከፈሎት የሚፈልጉትን ዋጋ ፣ የሚገኙበትን ሰዐት እና ለመስራት ፈቃደኛ የሆኑባቸውን የፕሮጀክቶች አይነትን ያካትታል።',
        text13: '',
        text14: 'ከላይ ያሉትን ሁሉንም እርከኖች ከጨረሱ በኋላ የሙያዎ መገለጫ በድህረ ገፁ ላይ ለማስተዋወቅ ክፍያውን መፈፀም አለብዎት። ክፍያ ከፈጸሙ በኋላ መገለጫዎን ንድህረ ገፁ ላይ ለደንበኛ እንዲገለፅ የሚያስችልዎ ቁልፍ ይከፈታል።',
        text15: 'Join Us and Start Selling Your Services On Our Website',
        text16: 'We hope this guide has been helpful in getting you started on the SIRA ALLE platform. If you have any further questions or need assistance, please do not hesitate to contact our customer service team. We wish you the best of luck in finding your next project and connecting with new clients on the SIRA ALLE platform.',
        text17: 'Sell Your Services',


    }
}

export const theme = {
    language: "english",
    text: english_text
}

export const ThemeContext = createContext(
    theme
)

