import { useEffect, useRef, useState } from "react"
import { Footer } from "../components/footer/Footer"
import { Header } from "../components/header"
import { ServiceHeader } from "../components/service/ServiceHeader"
import { useLocation } from "react-router-dom"
import { professional_services } from "../api/professional_services"
import { SearchBody } from "../components/search/SearchBody"

import '../../style/dark-paragraph.css'
import { Chat } from "../components/chat"

const Service = () => {

    const location = useLocation()

    let state = location.state ? location.state : ""
    const currentServiceId = state.currentServiceId
    const professionalId = state.professionalId
    const [professional, setProfessional] = useState({})
    const [currentService, setCurrentService] = useState({})
    const mapUrl = "https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=8.983299, 38.698674&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"

    const [activeElement, setActiveElement] = useState(1)
    const [iframSrc, setIframeUrl] = useState(mapUrl);
    const refIframe = useRef(null);
    const [map, setMap] = useState()
    useEffect(() => {
        professional_services(professionalId, (res) => {
            if (res.success) {
                setProfessional(res.data)
            }
        })

    }, [currentServiceId])

    useEffect(() => {
        if (professional.service_data != null && professional.service_data.length > 0) {
            professional.service_data.forEach((service) => {
                if (service._id == currentServiceId) {
                    setCurrentService(service)
                    if (service?.latitude && service?.longitude) {
                        const addr = service.latitude + ", " + service.longitude;
                        setMap(
                            "<iframe width='425' height='350' frameborder='0'scrolling='no' marginheight='0' marginwidth='0' src='https://maps.google.com/maps?&amp;q=" + encodeURIComponent(addr) + "&amp;output=embed'></iframe>"
                        )
                    }
                }
            })
        }

    }, [professional])


    const otherServices = () => {
        let ret = []
        if (professional.service_data != null) {
            if (professional.service_data.length > 1) {
                professional.service_data.forEach(data => {
                    if (data._id != currentServiceId) {
                        ret.push(data)
                    }
                })
            }
        }
        return (<SearchBody data={ret} cardCount={4} />)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <ServiceHeader title={currentService.title} />
            <section class="service-details-area padding-top-70 padding-bottom-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 order-2 order-lg-1">
                            <div class="service-details-wrapper">
                                <div class="service-details-inner">
                                    <div class="details-thumb">
                                        <div class="main-img-box ">
                                            <div class="service-details-slider">
                                                <div class="single-slider">
                                                    <div class="gallery-images single-featured">
                                                        <li>
                                                            <img src={"" + currentService.img} alt="" />
                                                        </li>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ul class="author-tag style-02 mt-4">
                                        <li class="tag-list">
                                            <a>
                                                <div class="authors">
                                                    <div class="thumb">
                                                        <img src={currentService.professionalImage != "" ? currentService.professionalImage : "imgs/user_profile.png"} alt="" />
                                                        <span class="notification-dot"></span>
                                                    </div>
                                                    <span class="author-title"> {professional.firstName} {professional.lastName} </span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>

                                    <ul class="details-tabs tabs margin-top-55">
                                        <li data-tab="tab1" className={"list " + (activeElement == 1 ? 'active' : '')} onClick={() => {
                                            setActiveElement(1)
                                        }}>
                                            Overview
                                        </li>
                                        <li className={"list " + (activeElement == 2 ? 'active' : '')} data-tab="tab2" onClick={() => {
                                            setActiveElement(2)
                                        }}>
                                            About Seller
                                        </li>
                                    </ul>
                                    <div className={"tab-content another-tab-content " + (activeElement == 1 ? 'active' : '')} id="tab1" style={{ marginTop: "20px" }}>
                                        <div class="details-content-tab padding-top-10 " dangerouslySetInnerHTML={{ __html: currentService.detail }} className="custom-editor">

                                        </div>
                                    </div>
                                    <div className={"tab-content another-tab-content " + (activeElement == 2 ? 'active' : '')} id="tab2">
                                        <div class="details-content-tab padding-top-10">
                                            <div class="about-seller-tab margin-top-30">
                                                <div class="about-seller-flex-content">
                                                    <div class="about-seller-thumb">
                                                        <img src={currentService.professionalImage != "" ? currentService.professionalImage : "imgs/user_profile.png"} alt="" />
                                                    </div>
                                                    <div class="about-seller-content">
                                                        <h5 class="title">  {professional.firstName} {professional.lastName}</h5>
                                                    </div>
                                                </div>
                                                <div class="seller-details-box margin-top-40">
                                                    <ul class="seller-box-list">
                                                        <li class="box-list"> From
                                                            <strong>
                                                                <h6>
                                                                    {currentService.city}
                                                                </h6>
                                                            </strong>
                                                        </li>
                                                        <li class="box-list"> Verified
                                                            <strong>
                                                                {
                                                                    currentService.professionalPaid && (
                                                                        <div class="award-icons style-02">
                                                                            <i class="las  la-check"></i>
                                                                        </div>)
                                                                }
                                                                {
                                                                    !currentService.professionalPaid && (
                                                                        <div class="award-icons style-02">
                                                                            <i class="las  la la-close"></i>
                                                                        </div>)
                                                                }
                                                            </strong>
                                                        </li>
                                                    </ul>
                                                    <div dangerouslySetInnerHTML={{ __html: map }}></div>
                                                    <p class="seller-details-para">{currentService.bio} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="another-details-wrapper padding-top-100">
                                <div class="section-title-two">
                                    <h3 class="title">Other Services of this Seller</h3>
                                    {/* <a href="seller/all-services/1.html" class="section-btn">Explore All</a> */}
                                </div>
                                <div class="row padding-top-20">
                                    {
                                        otherServices()
                                    }
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 margin-top-30  order-1 order-lg-2">
                            <Chat data={currentService.reviews ? currentService.reviews : []} />
                            <div class="service-details-package">
                                <div class="single-packages">
                                    <ul class="package-price">
                                        <li> Package </li>
                                        <li> {currentService.price} Birr </li>
                                    </ul>
                                    <div class="details-available-price margin-top-20">
                                        {/* <span class="summery-title">
                                            <ul class='onlilne-special-list'>
                                                <li><i class="las la-clock"></i> Delivery Days: {currentService.deliveryDay}</li>
                                            </ul>
                                        </span> */}

                                        <ul class="available-list">
                                            <li> Category: {currentService.category}</li>
                                        </ul>
                                    </div>
                                    <div class="btn-wrapper text-center margin-top-30">
                                        <a class="cmn-btn btn-bg-1 d-block" > Call Now </a>
                                    </div>
                                </div>
                                <div class="order-pagkages">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Service